import React, { useState } from "react";
import { supabase } from "../supabase/client";
import './Style.css';
import { useNavigate } from 'react-router-dom';

function ReseteoContraseña() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [token, setToken] = useState("");
  const navigate = useNavigate();

  const handleResetContraseña = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (password !== repeatPassword) {
      setError("Las contraseñas no coinciden. Por favor, inténtalo de nuevo.");
      setLoading(false);
      return;
    }

    try {
      // Verificar el token primero
      const { error: verifyError } = await supabase.auth.verifyOtp({
        email: email,
        token: token,
        type: "recovery",
      });

      if (verifyError) {
        throw verifyError;
      }

      // Actualizar la contraseña
      const { error: updateError } = await supabase.auth.updateUser({
        password: password,
      });

      if (updateError) {
        throw updateError;
      }
      alert("Cambio de contraseña correcto")
      navigate('/Login');
    } catch (error) {
      console.error("Error al resetear contraseña:", error);
      setError("Error al resetear contraseña. Por favor, verifica tus datos.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <body className="body-login">
      <div className="container" id="container">
        <div className="form-container sign-up-container">
          <form onSubmit={handleResetContraseña}>
            <h1>Reseteo De Contraseña</h1>
            <h4>Dinos tus datos e inserta el token que se te ha mandado</h4>
            <input
              type="text"
              placeholder="Inserte el token que se ha enviado"
              value={token}
              onChange={(e) => setToken(e.target.value)}
            />
            <input
              type="email"
              placeholder="Correo electronico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Contraseña minima de 6 digitos"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              type="password"
              placeholder="Repetir Contraseña"
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
            />
            <button
              type="submit"
              className="botonIniciarSesion"
              disabled={loading}
              color="primary"
            >
              {loading ? "Cargando..." : "Resetear Contraseña"}
            </button>
            {error && <div className="error-message">{error}</div>}
          </form>
        </div>
      </div>
    </body>
  );
}

export default ReseteoContraseña;
