import React from 'react';
import { Link } from 'react-router-dom';
const Nosotros = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', textAlign: 'center' }}>
      <h1>Quiénes Somos</h1>
      <p>
        Somos un grupo de tres apasionados desarrolladores que nos hemos unido con un objetivo común:
        crear una red social innovadora y única en su clase. Dos de nosotros somos expertos en el desarrollo de aplicaciones móviles,
        mientras que el tercero se especializa en el desarrollo web. Nuestra combinación de habilidades y experiencia nos permite
        abordar proyectos de manera integral y eficiente.
      </p>
      <p>
        Nuestra visión nació al identificar un hueco en el mercado que podíamos aprovechar. Nos dimos cuenta de que había una necesidad
        insatisfecha en el ámbito de los eventos astronómicos y decidimos tomar la iniciativa. Queremos brindar a nuestros usuarios una
        plataforma donde puedan compartir sus observaciones, publicar comentarios, seguir a otros entusiastas y participar en discusiones
        sobre el fascinante mundo de la astronomía.
      </p>
      <p>
        Actualmente, estamos dedicados a mejorar y escalar nuestra página para ofrecer una experiencia de usuario excepcional. Seguimos
        trabajando arduamente en las mejoras de nuestra plataforma, integrando nuevas funcionalidades como seguimientos, likes, dislikes,
        y mucho más. Nuestro objetivo es crear una comunidad vibrante y conectada donde cada usuario pueda descubrir, compartir y disfrutar
        de eventos astronómicos de manera única.
      </p>
      <p>
        Gracias por acompañarnos en este viaje. Estamos emocionados por lo que el futuro nos depara y esperamos que te unas a nuestra
        comunidad para explorar juntos el maravilloso universo de la astronomía.
      </p>
      <Link to="/Home" className="link-button">Volver a Inicio</Link>
    </div>
  );
};

export default Nosotros;