import { supabase } from '../supabase/client';
import './StyleHome.css';
import React, { useState, useEffect } from 'react';
import WhatsAppButton from './WhatsAppButton';
import { Helmet } from 'react-helmet';
import '@fortawesome/fontawesome-free/css/all.min.css';
import primera from './primera.jpg';
import segunda from './segunda.jpg';
import tercera from './tercera.jpg';
import cuarta from './cuarta.jpg';
import quinta from './quinta.jpg';
import sexta from './sexta.jpg';
import septima from './septima.jpg';
import octava from './octava.jpg';
import novena from './novena.jpg';
import noticia1 from './noticia1.jpeg';
import noticia2 from './noticia2.jpeg';
import logo from './logo.png';
import { Link } from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import GoogleMapsComponent from './GoogleMapsComponent';
function Home() {
  const buttonClass = 'bg-blue-500 text-white p-2 rounded-lg absolute top-0 right-0 mt-4 mr-4';
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [followButtonsState, setFollowButtonsState] = useState({});
  const [selectedDate, setDate] = useState(null);
  const [descripcionEvento, setDescripcionEvento] = useState('');
  const [username, setUsername] = useState('');
  const [eventos, setEventos] = useState([]);
  /*publicacion*/
  const [idUsuario, setId] = useState('');
  const [imgPerfil, setImgPerfil] = useState('');
  //Mostrar Publicacion
  const [publicaciones, setPublicaciones] = useState([]);
  //Usuarios que quizas conozcas
  const [usuariosAleatorios, setUsuariosAleatorios] = useState([]);
  const [randomUserFollowButtonsState, setRandomUserFollowButtonsState] = useState([]);
  //acordeon
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  //seguidores
  const [idusuarioseguidor, setidusuarioseguidor] = useState('');
  const [seguidores, setSeguidores] = useState([]);

registerLocale('es', es);
setDefaultLocale('es');


  const cerrarSesion = async () => {
    try {
      await supabase.auth.signOut();
    } catch (error) {
      console.error('Error al cerrar sesión:', error.message);
    }
  };
  

  const imagenesPublicidad = [primera,segunda,tercera,cuarta,quinta,sexta,septima,octava,novena];
  const [indiceImagen, setIndiceImagen] = useState(0);

  const cambiarImagen = () => {
    setIndiceImagen((prevIndice) => (prevIndice + 1) % imagenesPublicidad.length);
  };

  useEffect(() => {
    const intervalo = setInterval(cambiarImagen, 5000);
    return () => clearInterval(intervalo);
  }, []);

  const [textoOriginal, setTextoOriginal] = useState('');


  const handleTraducirClick = async (comentario, index) => {
    const API_KEY = 'ca9d6ebeb7msh728aa6c3563c780p194862jsnfb3d152aea91';
    const sourceLanguage = 'es';
    const targetLanguage = 'en';
    const texto = comentario;

    const url = `https://deep-translate1.p.rapidapi.com/language/translate/v2`;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-RapidAPI-Key': API_KEY,
        'X-RapidAPI-Host': 'deep-translate1.p.rapidapi.com'
      },
      body: JSON.stringify({
        q: texto,
        source: sourceLanguage,
        target: targetLanguage,
        format: 'text'
      })
    };

    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      if (data && data.data && data.data.translations && data.data.translations.translatedText) {
        const nuevosTextosTraducidos = [...textosTraducidos];
        nuevosTextosTraducidos[index] = data.data.translations.translatedText;
        setTextosTraducidos(nuevosTextosTraducidos);
      } else {
        console.error('No se pudo obtener la traducción');
      }
    } catch (error) {
      console.error('Error al traducir:', error);
    }
  };


  const searchUsers = async (query) => {
    try {
      const { data, error } = await supabase
        .from('user')
        .select('username, email, profile_picture, id_user')
        .ilike('username', `%${query}%`);

      if (error) {
        throw error;
      }

      return data || [];
    } catch (error) {
      console.error('Error searching users:', error.message);
      return [];
    }
  };

  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };
  const handleSearchSubmit = async (e) => {
    e.preventDefault();

    try {
      const results = await searchUsers(searchQuery);
      setSearchResults(results);

      const newFollowButtonsState = {};
      results.forEach(user => {
        newFollowButtonsState[user.id_user] = seguidores.has(user.id_user);
      });
      setFollowButtonsState(newFollowButtonsState);
    } catch (error) {
      console.error('Error searching users:', error.message);
    }
  };

  useEffect(() => {
    handleSearchInputChange({ target: { value: '' } });
  }, []);
  

  /*-------------evento---------------------------*/
  const obtenerEventos = async () => {
    try {
      const { data, error } = await supabase
        .from('events')
        .select('*')
        .order('time', { ascending: true });
  
      if (error) {
        throw error;
      }
  
      const eventosFiltrados = data
        .filter(evento => new Date(evento.time) > new Date())
        .map(evento => ({
          ...evento,
          time: new Date(evento.time).toLocaleDateString('es-ES')
        }));
  
      setEventos(eventosFiltrados);
    } catch (error) {
      console.error('Error al obtener los eventos:', error.message);
    }
  };

useEffect(() => {
  const fetchData = async () => {
    try {
      const { data } = await supabase.auth.getUser();
      if (data) {
        setUsername(data.user.user_metadata.username);
      }
    } catch (error) {
      console.error('Error fetching user data:', error.message);
    }
  };
  fetchData();
}, []);

const handleSubmit = async (event) => {
  event.preventDefault();   
  try {
    const { error } = await supabase
      .from('events')
      .insert([
        {
          time: selectedDate,
          title: event.target.titulo.value,
          description: event.target.descripcion.value,
          name_user: username 
        }
      ]);
      
    if (error) {
      throw error;
    }
      
    obtenerEventos();
      
    event.target.reset();
    setDate(null);
    setDescripcionEvento('');
  } catch (error) {
    console.error('Error al enviar los datos:', error.message);
  }
};
const handleDescripcionChange = (event) => {
  setDescripcionEvento(event.target.value);
};

useEffect(() => {
  obtenerEventos();
}, []);
  /*-------------publicacion---------------------------*/
  useEffect(() => {
    const fetchData = async () => {
        try {
            const { data } = await supabase.auth.getUser();
            if (data) {
                setId(data.user.user_metadata.sub);
            }
        } catch (error) {
            console.error('Error fetching user data:', error.message);
        }
    };
    fetchData();
  }, []);

  const handleFileUpload = async (event) => {
    try {
        const file = event.target.files[0];
        const { data, error } = await supabase.storage.from('pruebas/publicacion').upload(file.name, file);
        if (error) {
            throw error;
        }
        const imageName = data.path;
        const imagenPubli = await imagenUsuario(imageName);
        setImgPerfil(imagenPubli);
    } catch (error) {
        console.error('Error handling file upload:', error.message);
    }
  };

  const imagenUsuario = async (imageName) => {
    try {
      const { data, error } = await supabase.storage
        .from('pruebas/publicacion')
        .getPublicUrl(imageName);
      if (error) {
        throw error;
      }
  
      const imageUrl = data.publicUrl;
      return imageUrl;

    } catch (error) {
      console.error('Error al actualizar la imagen del usuario:', error.message);
    }
  };
  const handleEnviarClick = async () => {
    try {
      const textoPublicacion = document.querySelector('.texto-publicacion');
      const contenido = textoPublicacion.value;
      if (!contenido) {
        throw new Error('Por favor, ingrese contenido para la publicación.');
      }
      const { error } = await supabase
        .from('post')
        .insert([
          {
            id_user: idUsuario,
            description: contenido,
            content: imgPerfil,
          }
        ]);
      if (error) {
        throw error;
      }
      textoPublicacion.value = '';
      setImgPerfil('');
      obtenerPublicaciones();
    } catch (error) {
      console.error('Error al enviar la publicación:', error.message);
      alert('Se ha producido un error al enviar la publicación. Por favor, inténtelo de nuevo.');
    }
  };
  
  //------------------------------------------------Mostrar Publicacion---------------------------------------------------------------------
  useEffect(() => {
    obtenerPublicaciones();
  }, []);

  async function obtenerPublicaciones() {
    try {
      const { data, error } = await supabase
        .from('post')
        .select('content, description,id_post')
        .order('created_at', { ascending: false });
  
      if (error) {
        throw error;
      }
  
      setPublicaciones(data);
    } catch (error) {
      console.error('Error al obtener las publicaciones:', error.message);
    }
  }
//----------------------------Usuarios que quizas conozcas----------------------------------------------
const getAllUsers = async () => {
  try {
    const { data, error } = await supabase
      .from('user')
      .select('*');
    if (error) {
      throw error;
    }

    return data || [];
  } catch (error) {
    console.error('Error obteniendo usuarios:', error.message);
    return [];
  }
};
const getRandomUsersNotFollowed = async (currentUser, allUsers, followedUsers) => {
  if (!Array.isArray(allUsers) || allUsers.length === 0) {
    return [];
  }

  const filteredUsers = allUsers.filter(user => user.username !== currentUser && !followedUsers.has(user.id_user));

  if (filteredUsers.length < 3) {
    return filteredUsers;
  }

  const randomUsers = [];
  const totalUsers = filteredUsers.length;

  const randomIndices = [];
  while (randomIndices.length < 3) {
    const randomIndex = Math.floor(Math.random() * totalUsers);
    if (!randomIndices.includes(randomIndex)) {
      randomIndices.push(randomIndex);
    }
  }

  randomIndices.forEach(index => {
    randomUsers.push(filteredUsers[index]);
  });

  return randomUsers;
};


useEffect(() => {
  const fetchUsers = async () => {
    const allUsers = await getAllUsers();
    const currentUser = username;
    const followedUserIds = Array.from(seguidores); // Convertir el Set a Array para facilitar la comparación
    const randomUsers = await getRandomUsersNotFollowed(currentUser, allUsers, new Set(followedUserIds));
    const initialState = {};
    randomUsers.forEach((user, index) => {
      initialState[index] = false;
    });
    setRandomUserFollowButtonsState(initialState);
    setUsuariosAleatorios(randomUsers);
  };

  fetchUsers();
}, [seguidores]);

function mostrarUsuarios(users) {
  return users.map((user, index) => (
    <div key={index} className="usuario">
      <img src={user.profile_picture} alt={`Usuario ${user.username}`} className="imagenUsuarioPosible" />
      <span className="nombre-usuario">Nombre: {user.username}</span>
    </div>
  ));
}

const handleRandomUserFollowButtonClick = (index) => {
  setRandomUserFollowButtonsState((prevState) => ({
    ...prevState,
    [index]: !prevState[index]
  }));
};
//-------------------------------------------------acordeon----------------------------------------------------

useEffect(() => {
  const randomIndexes = [];
  while (randomIndexes.length < 2) {
    const randomIndex = Math.floor(Math.random() * accordionItems.length);
    if (!randomIndexes.includes(randomIndex)) {
      randomIndexes.push(randomIndex);
    }
  }
  setExpandedIndexes(randomIndexes);
}, []);

const handleToggle = (index) => {
  if (expandedIndexes.includes(index)) {
    setExpandedIndexes(expandedIndexes.filter((item) => item !== index));
  } else {
    setExpandedIndexes([index]);
  }
};
useEffect(() => {
  setExpandedIndexes([]);
}, []);
const accordionItems = [
  {
    title: 'La NASA revela las primeras imágenes del telescopio Webb de un universo nunca antes visto',
    content: 'El telescopio espacial James Webb ha presentado imágenes y datos pioneros, desde exoplanetas hasta galaxias lejanas, iniciando sus operaciones científicas tras su lanzamiento en diciembre de 2021.',
    image: noticia1,
  },
  {
    title: 'La NASA comparte avances sobre las primeras misiones lunares Artemis con tripulación',
    content: 'La NASA ajustó los calendarios de las misiones Artemis II y III para septiembre de 2025 y 2026, respectivamente, priorizando la seguridad de la tripulación. También revisan los plazos para el lanzamiento de los primeros elementos de Gateway y han solicitado a los proveedores del sistema de aterrizaje que apliquen conocimientos para futuras misiones.',
    image: noticia2,
  },
];
//-------------------------------------------comentarios----------------------------------------------
const [nombreUsuarioComentario, setNombreUsuarioComentario] = useState('');
const [idUsuarioComentario, setIdUsuarioComentario] = useState('');
const [comentarios, setComentarios] = useState([]);
const [desplegableAbierto, setDesplegableAbierto] = useState(null);
const [numeroComentarios, setNumeroComentarios] = useState([]);
const [numeroComentariosAnterior, setNumeroComentariosAnterior] = useState(0);
const [textosTraducidos, setTextosTraducidos] = useState(Array(publicaciones.length).fill(''));
const [likes, setLikes] = useState({});
const [dislikes, setDislikes] = useState({});
const [userInteractions, setUserInteractions] = useState({});
useEffect(() => {
  const fetchNumeroComentarios = async () => {
    try {
      const comentariosPromises = publicaciones.map(publicacion =>
        fetchComentarios(publicacion.id_post)
      );
      const comentarios = await Promise.all(comentariosPromises);
      setNumeroComentarios(comentarios.map(comentariosPorPublicacion => comentariosPorPublicacion.length));
    } catch (error) {
      console.error('Error al obtener el número de comentarios:', error.message);
    }
  };

  fetchNumeroComentarios();
}, [publicaciones]);
useEffect(() => {
  const fetchData = async () => {
    try {
      const { data } = await supabase.auth.getUser();
      if (data) {
        setNombreUsuarioComentario(data.user.user_metadata.username);
        setIdUsuarioComentario(data.user.user_metadata.sub);
      }
    } catch (error) {
      console.error('Error fetching user data:', error.message);
    }
  };
  fetchData();
}, []);
const fetchComentarios = async (postId) => {
  try {
    const { data, error } = await supabase
      .from('comment')
      .select('id_comment, comment, id_user, likes, dislikes, created_at', { count: 'exact' })
      .eq('id_post', postId)
      .order('created_at', { ascending: false });

    if (error) {
      throw error;
    }

    if (data) {
      const comentarios = await Promise.all(data.map(async (comentario) => {
        const { data: userData } = await supabase
          .from('user')
          .select('username, profile_picture')
          .eq('id_user', comentario.id_user);

        if (userData && userData.length > 0) {
          return {
            id_comment: comentario.id_comment,
            comment: comentario.comment,
            id_user: comentario.id_user,
            username: userData[0].username,
            profile_picture: userData[0].profile_picture,
            likes: comentario.likes || 0,
            dislikes: comentario.dislikes || 0,
            created_at: comentario.created_at,
          };
        } else {
          console.error('No se encontraron datos de usuario para el comentario:', comentario.id_user);
          return null;
        }
      }));

      return comentarios.filter(comentario => comentario !== null);
    }
  } catch (error) {
    console.error('Error al obtener comentarios:', error.message);
    return [];
  }
};

const mostrarPublicaciones = (publicaciones) => {
  const dropdownRefs = Array(publicaciones.length).fill(null);
  return publicaciones.map((publicacion, index) => (
    <div key={index} className="publicado">
      <div className='comentario-bonito'>
        <div><img src={publicacion.content || logo} alt={`Publicación ${index + 1}`} className="imagen-publicada" /></div>
        <div><textarea className="texto-publicado-usuario" value={publicacion.description} readOnly></textarea></div>
      </div>
      <button className='boton' value={publicacion.id_post} onClick={() => toggleDropdown(index, dropdownRefs, publicacion.id_post)}>
        <span>{numeroComentarios[index]}</span>💬
      </button>
      <div className="desplegable" style={{ display: 'none' }} ref={(ref) => dropdownRefs[index] = ref}>
        <div className="contenedor-scroll" value={publicacion.id_post}>
        {comentarios.map((comentario, comentarioIndex) => {
  const userInteraction = userInteractions[comentario.id_comment] || { liked: false, disliked: false };
  return (
    <div key={comentarioIndex} className="comentario-container-principal">
      <div className="usuario-info">
        <img src={comentario.profile_picture} className="Imagen-Perfil-Usuario" />
        <p className="nombre-usuario">{comentario.username}</p>
      </div>
      <div className="comentario-texto">
        <div>
          <textarea className="comentario" readOnly value={comentario.comment}></textarea>
          <textarea className='texto-traducido-ingles' readOnly value={textosTraducidos[comentarioIndex]} style={{ display: textosTraducidos[comentarioIndex] ? 'block' : 'none' }}></textarea>
        </div>
        <div>
          <button className="boton-traducir" onClick={() => handleTraducirClick(comentario.comment, comentarioIndex)}>Traducir</button>
          <button className='boton-like' onClick={() => handleLikeClick(comentario.id_comment)} disabled={userInteraction.disliked}>
            <span>{likes[comentario.id_comment] || comentario.likes}</span>👍🏾
          </button>
          <button className='boton-dislike'onClick={() => handleDislikeClick(comentario.id_comment)}disabled={userInteraction.liked}>
            <span>{dislikes[comentario.id_comment] || comentario.dislikes}</span>👎🏿
          </button>
        </div>
      </div>
    </div>
  );
})}
        </div>
        <div className='comentario-cajadecomentario'>
          <textarea
            className="caja-texto-comentario"
            id={`comentario-${publicacion.id_post}`}
            placeholder="Escribe aquí tu comentario..."
          ></textarea>
          <div>
            <button
              className='boton-enviar-comentario'
              onClick={() => handleEnviarComentarioClick(publicacion.id_post, index)}
              data-post-id={publicacion.id_post}
            >
              ✉️
            </button>
          </div>
        </div>
      </div>
    </div>
  ));
};
const handleLikeClick = async (commentId) => {
  if (!commentId) {
    console.error('commentId es indefinido');
    return;
  }

  try {
    const { data: comment, error: fetchError } = await supabase
      .from('comment')
      .select('likes, dislikes')
      .eq('id_comment', commentId)
      .single();

    if (fetchError) {
      console.error('Error al obtener el comentario:', fetchError.message);
      return;
    }

    const userInteraction = userInteractions[commentId] || { liked: false, disliked: false };

    if (userInteraction.liked) {
      // Quitar el like
      const newLikeCount = comment.likes - 1;
      const { error: updateError } = await supabase
        .from('comment')
        .update({ likes: newLikeCount })
        .eq('id_comment', commentId);

      if (updateError) {
        console.error('Error al quitar el like:', updateError.message);
        return;
      }

      setLikes(prevLikes => ({ ...prevLikes, [commentId]: newLikeCount }));
      setUserInteractions(prevInteractions => ({
        ...prevInteractions,
        [commentId]: { liked: false, disliked: false }
      }));
    } else {
      // Añadir el like
      const newLikeCount = comment.likes + 1;
      const updates = { likes: newLikeCount };
      if (userInteraction.disliked) {
        updates.dislikes = comment.dislikes - 1;
      }

      const { error: updateError } = await supabase
        .from('comment')
        .update(updates)
        .eq('id_comment', commentId);

      if (updateError) {
        console.error('Error al añadir el like:', updateError.message);
        return;
      }

      setLikes(prevLikes => ({ ...prevLikes, [commentId]: newLikeCount }));
      if (userInteraction.disliked) {
        setDislikes(prevDislikes => ({ ...prevDislikes, [commentId]: updates.dislikes }));
      }
      setUserInteractions(prevInteractions => ({
        ...prevInteractions,
        [commentId]: { liked: true, disliked: false }
      }));
    }
  } catch (error) {
    console.error('Error al manejar el like:', error.message);
  }
};

const handleDislikeClick = async (commentId) => {
  if (!commentId) {
    console.error('commentId es indefinido');
    return;
  }

  try {
    const { data: comment, error: fetchError } = await supabase
      .from('comment')
      .select('likes, dislikes')
      .eq('id_comment', commentId)
      .single();

    if (fetchError) {
      console.error('Error al obtener el comentario:', fetchError.message);
      return;
    }

    const userInteraction = userInteractions[commentId] || { liked: false, disliked: false };

    if (userInteraction.disliked) {
      // Quitar el dislike
      const newDislikeCount = comment.dislikes - 1;
      const { error: updateError } = await supabase
        .from('comment')
        .update({ dislikes: newDislikeCount })
        .eq('id_comment', commentId);

      if (updateError) {
        console.error('Error al quitar el dislike:', updateError.message);
        return;
      }

      setDislikes(prevDislikes => ({ ...prevDislikes, [commentId]: newDislikeCount }));
      setUserInteractions(prevInteractions => ({
        ...prevInteractions,
        [commentId]: { liked: false, disliked: false }
      }));
    } else {
      // Añadir el dislike
      const newDislikeCount = comment.dislikes + 1;
      const updates = { dislikes: newDislikeCount };
      if (userInteraction.liked) {
        updates.likes = comment.likes - 1;
      }

      const { error: updateError } = await supabase
        .from('comment')
        .update(updates)
        .eq('id_comment', commentId);

      if (updateError) {
        console.error('Error al añadir el dislike:', updateError.message);
        return;
      }

      setDislikes(prevDislikes => ({ ...prevDislikes, [commentId]: newDislikeCount }));
      if (userInteraction.liked) {
        setLikes(prevLikes => ({ ...prevLikes, [commentId]: updates.likes }));
      }
      setUserInteractions(prevInteractions => ({
        ...prevInteractions,
        [commentId]: { liked: false, disliked: true }
      }));
    }
  } catch (error) {
    console.error('Error al manejar el dislike:', error.message);
  }
};
const toggleDropdown = async (index, dropdownRefs, postId) => {
  const dropdown = dropdownRefs[index];
  if (dropdown) {
    if (postId === desplegableAbierto) {
      dropdown.style.display = 'none';
      setDesplegableAbierto(null);
      setTextosTraducidos(Array(publicaciones.length).fill(''));
    } else {
      dropdown.style.display = 'block';
      if (desplegableAbierto !== null) {
        const previousDropdown = dropdownRefs.find((ref, i) => publicaciones[i].id_post === desplegableAbierto);
        if (previousDropdown) {
          previousDropdown.style.display = 'none';
        }
      }
      setDesplegableAbierto(postId);
      const comentarios = await fetchComentarios(postId);
      setComentarios(comentarios);
      const numeroComentariosActualizado = comentarios.length;
      setNumeroComentariosAnterior(numeroComentarios[index]);
      setNumeroComentarios(numeroComentarios.map((numero, idx) => (idx === index ? numeroComentariosActualizado : numero)));
      const likesMap = {};
      const dislikesMap = {};
      comentarios.forEach(comentario => {
        likesMap[comentario.id_comment] = comentario.likes;
        dislikesMap[comentario.id_comment] = comentario.dislikes;
      });
      setLikes(likesMap);
      setDislikes(dislikesMap);
    }
  }
};
const handleEnviarComentarioClick = async (postId, publicacionIndex) => {
  try {
    const postIdAttr = `comentario-${postId}`;
    const TextoComentario = document.getElementById(postIdAttr);
    const miTextoComentado = TextoComentario.value;
    const { error } = await supabase
      .from('comment')
      .insert([
        {
          id_user: idUsuarioComentario,
          id_post: postId,
          comment: miTextoComentado,
        }
      ]);
    if (!error) {
      TextoComentario.value = '';
      console.log(`Enviando comentario para la publicación con ID ${postId}`);
      const comentariosActualizados = await fetchComentarios(postId);
      setComentarios(comentariosActualizados);
      const numeroComentariosActualizado = comentariosActualizados.length;
      setNumeroComentarios(numeroComentarios.map((numero, idx) => (idx === publicacionIndex ? numeroComentariosActualizado : numero)));
    }
  } catch (error) {
    console.error('Error al enviar el comentario:', error.message);
  }
};
//---------------------------------------------seguidores.-----------------------------------------------------------------------

useEffect(() => {
  const fetchData = async () => {
    try {
      const { data } = await supabase.auth.getUser();
      if (data) {
        const userId = data.user.user_metadata.sub;
        setidusuarioseguidor(userId);
        await fetchFollowedUsers(userId);
      }
    } catch (error) {
      console.error('Error fetching user data:', error.message);
    }
  };
  fetchData();
}, []);
const fetchFollowedUsers = async (userId) => {
  try {
    const { data, error } = await supabase
      .from('followers')
      .select('id_user_secundario')
      .eq('id_user_principal', userId);

    if (error) {
      throw error;
    }

    const followedUserIds = new Set(data.map(item => item.id_user_secundario));
    setSeguidores(followedUserIds);
  } catch (error) {
    console.error('Error fetching followed users:', error.message);
  }
};
const handleFollowButtonClick = async (userId) => {
  try {
    setFollowButtonsState((prevState) => ({
      ...prevState,
      [userId]: !prevState[userId]
    }));

    if (!followButtonsState[userId]) {
      await insertDataIntoTable(idusuarioseguidor, userId);
    } else {
      await deleteDataFromTable(idusuarioseguidor, userId);
    }
  } catch (error) {
    console.error('Error following user:', error.message);
  }
};

const insertDataIntoTable = async (idusuarioseguidor, userIdToFollow) => {
  try {
    const { data, error } = await supabase
      .from('followers')
      .insert([
        { 
          id_user_principal: idusuarioseguidor, 
          id_user_secundario: userIdToFollow
        }
      ]);

    if (error) {
      throw error;
    }

    console.log('Data inserted successfully:', data);
    setSeguidores((prev) => new Set(prev).add(userIdToFollow));
  } catch (error) {
    console.error('Error inserting data into Supabase table:', error.message);
    throw error;
  }
};

const deleteDataFromTable = async (idusuarioseguidor, userIdToUnfollow) => {
  try {
    const { data, error } = await supabase
      .from('followers')
      .delete()
      .eq('id_user_principal', idusuarioseguidor)
      .eq('id_user_secundario', userIdToUnfollow);

    if (error) {
      throw error;
    }

    console.log('Data deleted successfully:', data);
    setSeguidores((prev) => {
      const newSet = new Set(prev);
      newSet.delete(userIdToUnfollow);
      return newSet;
    });
  } catch (error) {
    console.error('Error deleting data from Supabase table:', error.message);
    throw error;
  }
};
//--------------------------------------------------------------------------------------------------------
const imagenRecogida = async (setId) => {
  try {
      const { data } = await supabase
          .from('user')
          .select('*')
          .eq('id_user', setId);
      return data[0].profile_picture;
  } catch (error) {
      console.error(error);
      return null;
  }
};
const [ImagenPrincipal, setImagenPrincipal] = useState('');
useEffect(() => {
  const fetchData = async () => {
      try {
          const { data } = await supabase.auth.getUser();
          if (data) {
            const imageUrl = await imagenRecogida(data.user.user_metadata.sub);
            setImagenPrincipal(imageUrl);
          }
      } catch (error) {
          console.error('Error fetching user data:', error.message);
      }
  };
  fetchData();
}, []);
const colorLineStyle = {
  display: 'inline-block',
  width: '35px',
  height: '5px',
  marginRight: '10px'
};

const redLineStyle = {
  ...colorLineStyle,
  backgroundColor: 'red'
};

const blueLineStyle = {
  ...colorLineStyle,
  backgroundColor: 'blue'
};
useEffect(() => {
  const title = document.getElementById("tituloEnGrande");
  const text = title.textContent;
  const repeatedText = text + " " + text; // Repetimos el texto para crear un bucle continuo
  title.innerHTML = `<span>${repeatedText}</span>`;
}, []);
  return (
    <>
      <div className='cajagrande'>
        <Link to="/Usuario">
          <img src={ImagenPrincipal} className='imagen-para-perfil' />
          <h5>Usuario</h5>
        </Link>
        <WhatsAppButton />
        <div class="tituloEnGrande-container">
          <h1 className="tituloEnGrande" id="tituloEnGrande">STAR PATH</h1>
        </div>
        <div className='caja3'>
          
          <button onClick={cerrarSesion} className={buttonClass}>
            Cerrar Sesión
          </button>
        </div>
      </div>

      <div className="tm-hero d-flex justify-content-center align-items-center" data-parallax="scroll" data-image-src="img/hero.jpg">
      <div className="accordion">
        {accordionItems.map((item, index) => (
        <div key={index} className={`accordion-item ${expandedIndexes.includes(index) ? 'expanded' : ''}`}>
          <div className="accordion-title" onClick={() => handleToggle(index)}>
            {item.title}
          </div>
          {expandedIndexes.includes(index) && (
            <div className="accordion-content">
              <p>{item.content}</p>
              <img src={item.image} alt={`Imagen ${index + 1}`} className='noticia-imagen'/>
            </div>
          )}
        </div>
        ))}
      </div>
      <div className='caja-busqueda'>
      <form className="d-flex tm-search-form" onSubmit={handleSearchSubmit}>
        <input 
          className="form-control tm-search-input" 
          type="search" 
          placeholder="Busca a tus amigos...." 
          aria-label="Search"
          value={searchQuery}
          onChange={handleSearchInputChange}
        />
      </form>
        <ul className='lista-busqueda'>
          {searchResults.map((user, index) => (
          <li key={index}>
            <img src={user.profile_picture} className='lista-busqueda-foto'/>
            <div className='lista-busqueda-nombre'>Nombre: {user.username}</div>
            <div className='lista-busqueda-email'>Email: {user.email}</div>
            <button 
                  type="submit"
                  className={`lista-busqueda-seguir ${followButtonsState[index] ? 'checked' : ''}`}
                  onClick={() => handleFollowButtonClick(user.id_user)}
                >
                  {followButtonsState[user.id_user] ? '✓' : 'Seguir'}
            </button>
          </li>))}
        </ul>
      </div>
      <div className="usuarios-aleatorios">
        <h1 className='Usuarios-Conocidos'>Usuarios que quizas conozcas</h1>
          {mostrarUsuarios(usuariosAleatorios)}
        </div>
      </div>

      <div className="body-cuerpo">
        <div className="body-izquierdo">
          <form onSubmit={handleSubmit}>
          <h2>Eventos</h2>
            <div className='evento-caja-rellenar'>
              <label htmlFor="titulo">Título:</label>
              <input type="text" id="titulo" name="titulo" required />
            </div>
            <div className='evento-caja-rellenar'>
              <label htmlFor="nombreUsuario">Nombre de Usuario:</label>
              <input type="text" id="nombreUsuario" name={username} value={username} readOnly />
            </div>
            <div className="evento-caja-rellenar">
              <label>Fecha del evento:</label>
              <DatePicker locale="es" dateFormat="dd/MM/yyyy" selected={selectedDate} onChange={date => setDate(date)} />
            </div>
            <div className="evento-caja-rellenar">
              <label htmlFor="descripcion">Descripción del evento:</label>
              <textarea id="descripcion" name="descripcion" value={descripcionEvento} onChange={handleDescripcionChange} required />
            </div>
            <button type="submit" className='boton-enviar-evento'></button>
          </form>
          <div className="tabla-eventos-container">
      <div className="tabla-eventos">
        <table>
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Título</th>
              <th>Descripción</th>
              <th>Usuario</th>
            </tr>
          </thead>
          <tbody>
            {eventos.map((evento, index) => (
              <tr key={index}>
                <td>{evento.time}</td>
                <td>{evento.title}</td>
                <td>{evento.description}</td>
                <td>{evento.name_user}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
        </div>
        <div className="body-medio">
          <div className='publicacion'>
            <img src={imgPerfil || logo} alt="Imagen" className='imagen-publicacion'/>
            <button className="boton-subir" onClick={() => document.getElementById('fileInput').click()}>Subir Imagen</button>
            <input id="fileInput" type="file" onChange={handleFileUpload} style={{ display: 'none' }} />
            <textarea className='texto-publicacion' placeholder='Cuentanos algo.....'></textarea>
            <button className="boton-publicar" onClick={handleEnviarClick }>Publicar</button>
          </div>
          <div className="contenedor-usuarios">
            {mostrarPublicaciones(publicaciones)}
          </div>
        </div>
        <div className="body-derecho">
          <img src={imagenesPublicidad[indiceImagen]} alt="Publicidad" className='Imagenes-banner'/>
          <h2>Miradores/Eventos de Madrid</h2>
      <div>
        <span style={redLineStyle}></span>
        <span>El icono rojo es para los miradores</span>
      </div>
      <div>
        <span style={blueLineStyle}></span>
        <span>El icono azul es para los eventos.</span>
      </div>
          <GoogleMapsComponent />
        </div>
      </div>

      <footer className="footer">
        <div>
          <div>
            <ul>
              <Link to="/Coockies">Coockies</Link>
              <br/><br/>
              <Link to="/Nosotros">¿Quieres saber mas sobre los creadores de StarPath?</Link>
            </ul>
            <h4>Si necesitas ayuda contactanos a traves de nuestro correo: info@starpath.es</h4>
          </div>
          <div></div>
        </div>
      </footer>
    </>
  );
}

export default Home;
