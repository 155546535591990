import React, { useEffect,useRef, useState } from 'react';
import './Chat.css';
import getChatData from './getChatData'; // Importación de función para obtener datos de chat
import { supabase } from '../supabase/client'; // Importación del cliente de Supabase
import BotonHome from './BotonHome'; // Componente para botón de inicio
import getUserDataAsync from './getUserDataAsync'; // Función asincrónica para obtener datos de usuario
import logo from './logo.png'; // Imagen del logo (ejemplo)
import { useLocation } from 'react-router-dom';

const getUserDataForReceiver = async (receiverUserId) => {
    try {
        const userData = await getUserDataAsync(receiverUserId); // Obtener datos del usuario receptor
        return userData;
    } catch (error) {
        console.error('Error fetching receiver user data:', error.message); // Manejo de errores
    }
};

const getMessages = async (senderId, receiverId) => {
    try {
        // Obtener mensajes ordenados por fecha de creación ascendente
        const { data, error } = await supabase
            .from('message')
            .select('*')
            .or(`and(id_user_sender.eq.${senderId},id_user_receiver.eq.${receiverId}),and(id_user_sender.eq.${receiverId},id_user_receiver.eq.${senderId})`)
            .order('created_at', { ascending: true });

            return data || [];
    } catch (error) {
        console.error('Error fetching messages:', error.message); // Manejo de errores
        return [];
    }
};
const checkExistingConversation = async (senderId, receiverId) => {
    try {
        const { data, error } = await supabase
            .from('message')
            .select('id')
            .or(`and(id_user_sender.eq.${senderId},id_user_receiver.eq.${receiverId}),and(id_user_sender.eq.${receiverId},id_user_receiver.eq.${senderId})`)
            .limit(1);

        if (error) {
            console.error('Error checking existing conversation:', error.message);
            return false;
        }

        return data.length > 0;
    } catch (error) {
        console.error('Error checking existing conversation:', error.message);
        return false;
    }
};

const Chat = () => {
    const [chatData, setChatData] = useState([]); // Estado para almacenar los datos del chat
    const [idUsuario, setIdUsuario] = useState(''); // Estado para almacenar el ID del usuario actual
    const [selectedChat, setSelectedChat] = useState(null); // Estado para almacenar el chat seleccionado
    const [messages, setMessages] = useState([]); // Estado para almacenar los mensajes del chat
    const [mensajeEnviar, setMensajeEnviar] = useState(''); // Estado para almacenar el mensaje que se va a enviar
    const [IdUserRefresco, setIdUserRefresco] = useState(''); // Estado para almacenar el ID de usuario para refrescar
    const location = useLocation();
    const { state } = location;
    const receiverUserId = state ? state.receiverUserId : null;
    const scrollToBottom = () => {
        const chatContainer = document.querySelector('.chat-messages');
        if (chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight;
        }
    };
    // Efecto para obtener y establecer el ID de usuario actual al cargar el componente
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const { data } = await supabase.auth.getUser(); // Obtener datos del usuario autenticado
                if (data) {
                    setIdUsuario(data.user.user_metadata.sub); // Establecer el ID del usuario actual
                }
            } catch (error) {
                console.error('Error fetching user data:', error.message); // Manejo de errores
            }
        };

        fetchUserData(); // Llamar a la función para obtener datos de usuario al montar el componente
    }, []);

    // Efecto para obtener los datos de chat al cambiar el ID de usuario
    useEffect(() => {
        const fetchData = async () => {
            if (idUsuario) {
                const data = await getChatData(idUsuario); // Obtener datos de chat basados en el ID de usuario
                setChatData(data); // Establecer los datos de chat en el estado
            }
        };

        fetchData(); // Llamar a la función para obtener datos de chat al cambiar el ID de usuario
    }, [idUsuario]);

    // Efecto para obtener los mensajes al seleccionar un chat
    useEffect(() => {
        const fetchMessages = async () => {
            if (selectedChat || receiverUserId) {
                const chatUserId = selectedChat ? selectedChat.receiverUserId : receiverUserId;
                const data = await getMessages(idUsuario, chatUserId);
                setMessages(data);
                scrollToBottom();
            }
        };
        fetchMessages();
    }, [selectedChat, receiverUserId, idUsuario]);
    useEffect(() => {
        const checkAndFetchMessages = async () => {
            if (receiverUserId && !selectedChat) {
                const existingConversation = await checkExistingConversation(idUsuario, receiverUserId);
                if (existingConversation) {
                    const newChat = {
                        receiverUserId: receiverUserId
                    };
                    setSelectedChat(newChat);
                } else {
                    const newChat = {
                        receiverUserId: receiverUserId
                    };
                    setSelectedChat(newChat);
                    setMessages([]);
                }
            }
        };
    
        checkAndFetchMessages();
    }, [receiverUserId, idUsuario]);
    useEffect(() => {
        if (receiverUserId && !selectedChat) {
            const newChat = {
                receiverUserId: receiverUserId
            };
            setSelectedChat(newChat);
        }
    }, [receiverUserId]);
    useEffect(() => {
        if (messages.length > 0) {
            scrollToBottom();
        }
    }, [messages]);
    // Efecto para obtener y establecer el ID de usuario para refresco al cargar el componente
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const { data } = await supabase.auth.getUser(); // Obtener datos del usuario autenticado
                if (data) {
                    setIdUserRefresco(data.user.user_metadata.sub); // Establecer el ID de usuario para refresco
                }
            } catch (error) {
                console.error('Error fetching user data:', error.message); // Manejo de errores
            }
        };

        fetchUserData(); // Llamar a la función para obtener datos de usuario al montar el componente
    }, []);

    // Efecto para manejar la suscripción a nuevos mensajes
    let subscription;
    useEffect(() => {
        const handleSubscription = () => {
            subscription = supabase
                .channel('room1')
                .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'message' }, payload => {
                    console.log('Nuevo mensaje recibido:', payload.new);
                    const { id_user_sender, id_user_receiver } = payload.new;
                    if ((id_user_sender === IdUserRefresco || id_user_receiver === IdUserRefresco) &&
                        (id_user_sender === selectedChat.receiverUserId || id_user_receiver === selectedChat.receiverUserId)) {
                        getMessages(idUsuario, selectedChat.receiverUserId)
                            .then(newMessages => {
                                setMessages(newMessages); // Actualizar mensajes con los nuevos mensajes recibidos
                            })
                            .catch(error => {
                                console.error('Error al obtener mensajes después de inserción:', error.message);
                            });
                    }
                })
                .subscribe();
                
        };
    
        if (IdUserRefresco && selectedChat) {
            handleSubscription(); // Manejar la suscripción solo si hay un ID de usuario para refresco y un chat seleccionado
        }
    
        return () => {
            if (subscription) {
                supabase.removeChannel(subscription); // Limpiar la suscripción al desmontar el componente
            }
        };
    }, [IdUserRefresco, selectedChat, idUsuario]);

    // Función para enviar un mensaje
    const enviarMensaje = async () => {
        if (mensajeEnviar.trim() === '') {
            alert('El mensaje no puede estar vacío'); // Mostrar alerta si el mensaje está vacío
            return;
        }

        try {
            await supabase.from('message').insert([
                {
                    id_user_sender: idUsuario,
                    id_user_receiver: selectedChat.receiverUserId,
                    message: mensajeEnviar.trim(),
                },
            ]);

            const updatedMessages = await getMessages(idUsuario, selectedChat.receiverUserId);
            setMessages(updatedMessages);
            setMensajeEnviar('');
            console.log('Mensaje enviado con éxito');
            scrollToBottom();
        } catch (error) {
            console.error('Error al enviar mensaje:', error.message); // Mostrar error si falla el envío del mensaje
        }
    };

    // Función para manejar el evento de tecla presionada
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            enviarMensaje(); // Llamar a la función para enviar mensaje al presionar Enter
        }
    };

    return (
        <div className="container-chat">
            <div className="left-div-chat">
                <h1>Chats</h1>
                <BotonHome /> {/* Componente para botón de inicio */}
                {chatData.map((chat, index) => (
                    <div key={index} onClick={() => setSelectedChat(chat)}>
                        <UserDataDisplay receiverUserId={chat.receiverUserId} />
                    </div>
                ))}
            </div>
            <div className="right-div-chat">
                <ChatHeader receiverUserId={selectedChat ? selectedChat.receiverUserId : null} />
                <div className='parte-chat'>
                    <ChatMessages messages={messages} />
                </div>
                <div className='envio-mensaje-chat'>
                    <input
                        type="text"
                        placeholder="Escribe tu mensaje aquí..."
                        value={mensajeEnviar}
                        onChange={(e) => setMensajeEnviar(e.target.value)}
                        onKeyDown={handleKeyPress}/>
                    <button className='boton-enviar-chat' onClick={enviarMensaje}>
                        <i className="fas fa-paper-plane"></i>
                    </button>
                </div>
            </div>
        </div>
    );
};

// Componente para mostrar datos del usuario
const UserDataDisplay = ({ receiverUserId }) => {
    const [userData, setUserData] = useState(null); // Estado para almacenar datos del usuario

    useEffect(() => {
        const fetchUserData = async () => {
            const userData = await getUserDataForReceiver(receiverUserId); // Obtener datos del usuario receptor
            setUserData(userData); // Establecer datos del usuario en el estado
        };

        fetchUserData(); // Llamar a la función para obtener datos del usuario al montar el componente
    }, [receiverUserId]);

    if (!userData) {
        return <p>Loading user data...</p>; // Mostrar mensaje de carga si no hay datos de usuario
    }    return (
        <div className="chat-personas">
            <img src={userData.profilePicture} alt="Profile Picture" /> {/* Mostrar imagen de perfil del usuario */}
            <p>{userData.username}</p> {/* Mostrar nombre de usuario */}
        </div>
    );
};

// Componente para el encabezado del chat
const ChatHeader = ({ receiverUserId }) => {
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        if (receiverUserId) {
            const fetchUserData = async () => {
                const userData = await getUserDataForReceiver(receiverUserId);
                setUserData(userData);
            };

            fetchUserData();
        }
    }, [receiverUserId]);

    if (!receiverUserId) {
        return (
            <div className='encabezado-chat'>
                <img src={logo} alt="Default Profile" />
                <p>Selecciona un chat para empezar</p>
            </div>
        );
    }

    if (!userData) {
        return <p>Cargando datos del usuario...</p>;
    }

    return (
        <div className='encabezado-chat'>
            <img src={userData.profilePicture} alt="Profile Picture" />
            <p>{userData.username}</p>
        </div>
    );
}

// Componente para mostrar mensajes de chat
const ChatMessages = ({ messages }) => {
    const [id_usuario_logeado, setIdParaMensaje] = useState('');
    const messagesEndRef = useRef(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const { data } = await supabase.auth.getUser();
                if (data) {
                    setIdParaMensaje(data.user.user_metadata.sub);
                }
            } catch (error) {
                console.error('Error fetching user data:', error.message);
            }
        };

        fetchUserData();
    }, []);
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);
    return (
        <div className="chat-messages">
            {messages.length === 0 ? (
                <div>No hay mensajes</div>
            ) : (
                messages.map((msg, index) => (
                    <div
                        key={index}
                        className={`message ${msg.id_user_sender === id_usuario_logeado ? 'sent' : 'received'}`}>
                        <p>{msg.message}</p>
                    </div>
                ))
            )}
        </div>
    );
};

export default Chat; // Exportar el componente Chat
