import React from 'react';
import { Link } from 'react-router-dom';
import './Coockies.css';
const CookiePolicy = () => {
    return (
        <body className='body-coockies'>
            <div className="container-coockies">
            <h1>Política de Cookies de Starpath</h1>
            <p><strong>Última actualización: 30 de mayo de 2024</strong></p>

            <p>En Starpath, utilizamos cookies y tecnologías similares para mejorar tu experiencia en nuestro sitio web, analizar nuestro tráfico y personalizar el contenido y los anuncios. A continuación, te ofrecemos una visión detallada sobre las cookies que utilizamos y cómo puedes gestionarlas.</p>

            <h2 className="section-title">Tipos de Cookies que Utilizamos</h2>
            
            <h3 className="subsection-title">1. Cookies Esenciales</h3>
            <p>Estas cookies son fundamentales para el funcionamiento básico de Starpath. Permiten funcionalidades como la autenticación y la gestión de tu sesión.</p>
            <ul>
                <li><strong>Propósito:</strong> Garantizar que puedes navegar por nuestro sitio de manera segura y acceder a las funciones básicas.</li>
                <li><strong>Ejemplos:</strong> <span className="code-example">session_id</span> (Mantiene tu sesión iniciada durante la navegación), <span className="code-example">security_token</span> (Protege tu cuenta contra accesos no autorizados).</li>
            </ul>
            
            <h3 className="subsection-title">2. Cookies de Rendimiento</h3>
            <p>Estas cookies recopilan información sobre cómo los usuarios utilizan Starpath, permitiéndonos mejorar la funcionalidad y el rendimiento del sitio.</p>
            <ul>
                <li><strong>Propósito:</strong> Analizar el tráfico y uso del sitio web para identificar problemas y áreas de mejora.</li>
                <li><strong>Ejemplos:</strong> <span className="code-example">_ga</span> (Usada por Google Analytics para distinguir a los usuarios y sesiones), <span className="code-example">_gat</span> (Usada por Google Analytics para reducir la tasa de solicitudes).</li>
            </ul>

            <h3 className="subsection-title">3. Cookies de Funcionalidad</h3>
            <p>Estas cookies permiten que Starpath recuerde las elecciones que haces (como tu nombre de usuario, idioma o región) y proporcionan características mejoradas y más personalizadas.</p>
            <ul>
                <li><strong>Propósito:</strong> Ofrecerte una experiencia más personalizada y recordar tus preferencias.</li>
                <li><strong>Ejemplos:</strong> <span className="code-example">user_locale</span> (Guarda la preferencia de idioma del usuario), <span className="code-example">theme_preference</span> (Guarda la preferencia del usuario sobre el tema (claro u oscuro)).</li>
            </ul>

            <h3 className="subsection-title">4. Cookies de Publicidad/Marketing</h3>
            <p>Estas cookies se utilizan para hacer que los anuncios sean más relevantes para ti. También nos ayudan a medir la efectividad de nuestras campañas publicitarias.</p>
            <ul>
                <li><strong>Propósito:</strong> Mostrar anuncios personalizados y medir el rendimiento de nuestras campañas publicitarias.</li>
                <li><strong>Ejemplos:</strong> <span className="code-example">ad_id</span> (Usada para identificar a los usuarios y mostrar anuncios personalizados), <span className="code-example">campaign_data</span> (Recopila datos sobre la efectividad de las campañas publicitarias).</li>
            </ul>

            <h2 className="section-title">Duración de las Cookies</h2>
            <p><strong>Cookies de Sesión:</strong> Se eliminan al cerrar el navegador.</p>
            <p><strong>Cookies Persistentes:</strong> Permanecen en tu dispositivo durante un período definido o hasta que las elimines manualmente. Por ejemplo, algunas cookies pueden expirar después de 30 días, mientras que otras pueden durar varios meses.</p>

            <h2 className="section-title">Cookies de Terceros</h2>
            <p>Utilizamos servicios de terceros que también pueden almacenar cookies en tu dispositivo para proporcionar funcionalidades adicionales y mejorar el rendimiento de nuestro sitio. Algunos de estos terceros incluyen:</p>
            <ul>
                <li><strong>Google Analytics</strong>
                    <ul>
                        <li><strong>Propósito:</strong> Analizar el uso del sitio web y generar informes sobre la actividad del usuario.</li>
                        <li><a href="https://policies.google.com/privacy">Google Analytics Privacy Policy</a></li>
                    </ul>
                </li>
                <li><strong>Facebook Pixel</strong>
                    <ul>
                        <li><strong>Propósito:</strong> Medir la efectividad de nuestras campañas publicitarias y ofrecer anuncios personalizados en Facebook.</li>
                        <li><a href="https://www.facebook.com/policy.php">Facebook Privacy Policy</a></li>
                    </ul>
                </li>
                <li><strong>Hotjar</strong>
                    <ul>
                        <li><strong>Propósito:</strong> Analizar el comportamiento del usuario mediante mapas de calor y grabaciones de sesiones.</li>
                        <li><a href="https://www.hotjar.com/legal/policies/privacy/">Hotjar Privacy Policy</a></li>
                    </ul>
                </li>
            </ul>

            <h2 className="section-title">Gestión de tus Preferencias de Cookies</h2>
            <p>Puedes gestionar tus preferencias de cookies en cualquier momento desde nuestro sitio web. A continuación, te ofrecemos las opciones disponibles:</p>
            <ul>
                <li><a href="#">Aceptar todas las cookies</a></li>
                <li><a href="#">Rechazar cookies no esenciales</a></li>
                <li><a href="#">Configurar cookies</a></li>
            </ul>

            <h3 className="subsection-title">Configuración Personalizada</h3>
            <p>En la configuración personalizada, puedes seleccionar qué tipos de cookies deseas permitir o rechazar:</p>
            <ul>
                <li><strong>Cookies Esenciales:</strong> (No se pueden desactivar)
                    <ul>
                        <li>[ ] Siempre activas.</li>
                    </ul>
                </li>
                <li><strong>Cookies de Rendimiento:</strong>
                    <ul>
                        <li>[ ] Permitir</li>
                        <li>[ ] Rechazar</li>
                    </ul>
                </li>
                <li><strong>Cookies de Funcionalidad:</strong>
                    <ul>
                        <li>[ ] Permitir</li>
                        <li>[ ] Rechazar</li>
                    </ul>
                </li>
                <li><strong>Cookies de Publicidad/Marketing:</strong>
                    <ul>
                        <li>[ ] Permitir</li>
                        <li>[ ] Rechazar</li>
                    </ul>
                </li>
            </ul>

            <h2 className="section-title">Más Información</h2>
            <p>Para obtener más detalles sobre cómo utilizamos las cookies y tus derechos con respecto a tus datos personales, consulta nuestra <a href="https://starpath.com/privacy">Política de Privacidad</a>.</p>

            <Link to="/Home" className="link-button">Volver a Inicio</Link>
        </div>
        </body>
    );
};

export default CookiePolicy;
