import React, { useState } from "react";
import { supabase } from "../supabase/client";
import './Style.css';
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loadingLogin, setLoadingLogin] = useState(false);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setLoadingLogin(true);

    try {
      const { error } = await supabase.auth.signInWithPassword({ email, password });
      
      if (error) {
        throw error;
      }
      Swal.fire({
        title: '¡Éxito!',
        text: 'Inicio de sesión exitoso.',
        icon: 'success',
      });
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      Swal.fire({
        title: 'Error',
        text: 'Error al iniciar sesión. Por favor, verifica tus credenciales.',
        icon: 'error',
      });
    } finally {
      setLoadingLogin(false);
    }
  };

  return (
    <body className="body-login">
      <div className="container" id="container">
        <div className="form-container sign-up-container">
          <form onSubmit={handleLoginSubmit}>
            <h1>Inicia Sesión</h1>
            <h4>Rellena los siguientes campos de tu cuenta</h4>
            <input
              type="email"
              placeholder="Correo electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit" className="botonIniciarSesion" disabled={loadingLogin} color="primary">
              {loadingLogin ? "Cargando..." : "Iniciar Sesión"}
            </button>
          </form>
          <Link to="/OlvidoContraseña">¿Olvidaste la contraseña?</Link>
          <br></br>
          <Link to="/Register">¿No tienes cuenta? Regístrate aquí</Link>
        </div>
      </div>
    </body>
  );
}

export default Login;
