import React,{ useEffect, useState }  from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { supabase } from '../supabase/client';

const MAP_API_KEY = "AIzaSyBZ81gtv6E7R5JurctaWTkTRF4Fhbky2qM";

const mapContainerStyle = {
  height: "380px",
  width: "400px"
};

const center = {
  lat: 40.416775,
  lng: -3.703790
};

const miradores = [
  { name: "Mirador de las Vistillas", lat: 40.412614, lng: -3.714876 },
  { name: "Círculo de Bellas Artes", lat: 40.418099, lng: -3.694297 },
  { name: "Palacio de Cibeles", lat: 40.419439, lng: -3.693736 },
  { name: "Templo de Debod", lat: 40.424021, lng: -3.714771 },
  { name: "Mirador del Faro de Moncloa", lat: 40.438131, lng: -3.719694 },
  { name: "Mirador de Madrid Río", lat: 40.398873, lng: -3.713759 },
  { name: "Cerro del Tío Pío (Parque de las Siete Tetas)", lat: 40.401905, lng: -3.657642 },
  { name: "Silo de Hortaleza", lat: 40.480079, lng: -3.651635 },
  { name: "Cúpula de la Catedral de la Almudena", lat: 40.415714, lng: -3.714396 },
  { name: "Mirador de la Casa de Campo", lat: 40.413800, lng: -3.754850 },
  { name: "Parque de la Cuña Verde de O'Donnell", lat: 40.418075, lng: -3.646292 },
  { name: "Azotea del Hotel Riu Plaza España", lat: 40.423937, lng: -3.711366 },
  { name: "Parque de Enrique Tierno Galván", lat: 40.391409, lng: -3.679171 },
  { name: "Mirador del Cerro Almodóvar", lat: 40.383738, lng: -3.635089 },
  { name: "Parque Lineal del Manzanares", lat: 40.373178, lng: -3.699297 },
];

const GoogleMapsComponent = () => {
  const [icon, setIcon] = useState(null);
  const [eventLocations, setEventLocations] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.google && window.google.maps) {
        const largeIcon = {
          url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
          scaledSize: new window.google.maps.Size(50, 50) // Escalar el tamaño del icono
        };
        setIcon(largeIcon);
        clearInterval(interval);
      }
    }, 100);

    // Fetch event locations from Supabase
    const fetchEventLocations = async () => {
      const { data: locationData, error: locationError } = await supabase
        .from('event_location')
        .select('id, latitude, longitude');

      if (locationError) {
        console.error('Error fetching event locations:', locationError);
        return;
      }

      const eventIds = locationData.map(loc => loc.id);
      
      // Fetch event titles using the ids
      const { data: eventData, error: eventError } = await supabase
        .from('events')
        .select('id, title')
        .in('id', eventIds);

      if (eventError) {
        console.error('Error fetching event titles:', eventError);
        return;
      }

      // Merge location data with event titles
      const eventsWithTitles = locationData.map(loc => {
        const event = eventData.find(event => event.id === loc.id);
        return {
          ...loc,
          title: event ? event.title : 'Event'
        };
      });

      setEventLocations(eventsWithTitles);
    };

    fetchEventLocations();
  }, []);

  return (
    <LoadScript googleMapsApiKey={MAP_API_KEY}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={12}
      >
        {icon && miradores.map((mirador, index) => (
          <Marker 
            key={index}
            position={{ lat: mirador.lat, lng: mirador.lng }}
            title={mirador.name}
            icon={icon}
          />
        ))}
        {eventLocations.map((location, index) => (
          <Marker 
            key={location.id}
            position={{ lat: location.latitude, lng: location.longitude }}
            title={location.title}
            icon={{
              url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
              scaledSize: new window.google.maps.Size(50, 50) // Escalar el tamaño del icono
            }}
          />
        ))}
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleMapsComponent;