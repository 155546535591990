import { supabase } from '../supabase/client';

async function getChatData(userId) {
    let listChatData = [];
    let receiverList = [];

    try {
        const { data, error } = await supabase.rpc('getbothuserchats', { idloggeduser: userId });
        if (error) {
            throw error;
        }
        
        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            
            // Solo consideramos los chats donde el usuario logueado es el remitente
            if (item.id_user_sender === userId) {
                let chatData = {
                    senderUser: item.id_user_sender,
                    receiverUserId: item.id_user_receiver // Agregamos el ID del receptor
                };

                const lastMessageData = await getLastMessage(userId, item.id_user_receiver);
                chatData.lastMessage = lastMessageData[0];
                chatData.lastMessageSender = lastMessageData[1];

                listChatData.push(chatData);
            }

            receiverList.push(item.id_user_receiver);
        }
        
    } catch (error) {
        console.error('Error fetching chat data:', error.message);
    }

    return listChatData;
}

async function getLastMessage(userId, otherUserId) {
    // Implementa la lógica real para obtener el último mensaje entre userId y otherUserId desde Supabase
    return ['Último mensaje', 'Remitente'];
}

export default getChatData;
