import React, { useEffect, useState } from 'react';
import { supabase } from '../supabase/client';
import './Primera.css';
import logo from './logo.png';
import { useNavigate } from 'react-router-dom';

function Primera() {
    const [username, setUsername] = useState('');
    const [idUsuario, setId] = useState('');
    const [imgPerfil, setImgPerfil] = useState('');
    const [biografia, setBiografia] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await supabase.auth.getUser();
                if (data) {
                    setUsername(data.user.user_metadata.username);
                    setId(data.user.user_metadata.sub);
                    const imageUrl = await imagenRecogida(data.user.user_metadata.sub);
                    setImgPerfil(imageUrl);
                }
            } catch (error) {
                console.error('Error fetching user data:', error.message);
            }
        };
        fetchData();
    }, []);

    const handleFileUpload = async (event) => {
        try {
            const file = event.target.files[0];
            const { data, error } = await supabase.storage.from('pruebas/FotosPruebas').upload(file.name, file);
            if (error) {
                throw error;
            }
            const imageName = data.path;
            await imagenUsuario(imageName);
            const imageUrl = await imagenRecogida(idUsuario);
            setImgPerfil(imageUrl);
        } catch (error) {
            console.error('Error handling file upload:', error.message);
        }
    };

    const imagenUsuario = async (imageName) => {
        try {
            const { data, error } = supabase.storage
                .from('pruebas/FotosPruebas')
                .getPublicUrl(imageName);
            if (error) {
                throw error;
            }
            const imageUrl = data.publicUrl;

            const { error: updateError } = await supabase
                .from('user')
                .update({ profile_picture: imageUrl })
                .eq('id_user', idUsuario);
            if (updateError) {
                throw updateError;
            }
        } catch (error) {
            console.error('Error updating profile picture:', error.message);
        }
    };
    
    const imagenRecogida = async (setId) => {
        try {
            const { data } = await supabase
                .from('user')
                .select('*')
                .eq('id_user', setId);
            return data[0].profile_picture;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    const handleBiografiaChange = (event) => {
        setBiografia(event.target.value);
    };

    const handleEnviarClick = async () => {
        try {
            await supabase
                .from('user')
                .update({ 
                    bio: biografia,
                    first_time:2
                 })
                .eq('id_user', idUsuario);
                navigate('/Home')
        } catch (error) {
            console.error('Error enviando la biografía:', error.message);
        }
    };
    return (
        <body className='body-primera'>
            <div className="biografia-contenedora">
                <div className='cabeza-primera'>
                    <div className='lado-izquierda'>
                        <img src={logo} className="logo" />
                        <h1 className="welcome-text">Bienvenido {username} </h1>
                    </div>
                    <div className='lado-derecha'>
                        <img src={imgPerfil} className='Imagen-Perfil-Usuario'/>
                        <button className="choose-image-btn" onClick={() => document.getElementById('fileInput').click()}>Elige tu imagen</button>
                        <input id="fileInput" type="file" onChange={handleFileUpload} style={{ display: 'none' }} />
                    </div>
                </div>
            <div>
                <div className="bio-container">
                    <h2 className="bio-title">Biografía</h2>
                    <textarea
                        className="bio-textarea"
                        value={biografia}
                        onChange={handleBiografiaChange}
                        placeholder="Escribe tu biografía aquí..."
                    />
                    <button className="enviar-btn" onClick={handleEnviarClick}>Enviar</button>
                </div>
            </div>
        </div>
        </body> 
    );
}

export default Primera;
