import './App.css';
import Login from './pages/Login'
import { Routes, Route, useNavigate} from 'react-router-dom'
import Home from './pages/Home'
import Register from './pages/Register'
import Usuario from './pages/Usuario'
import Primera from './pages/Primera'
import Coockies from './pages/Coockies'
import Nosotros from './pages/Nosotros'
import Chat from './pages/Chat'
import OlvidoContraseña from './pages/OlvidoContraseña'
import ReseteoContraseña from './pages/ReseteoContraseña'
import { useEffect} from 'react';
import { supabase } from './supabase/client'


function App() {
  const navigate = useNavigate();

  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        console.log("Usuario autenticado:", session.user.email);
        primeraVez(session.user.id)
        .then(result => {
        if (result === 1) {
          navigate('/Primera');
        } else if(result ===2) {
          navigate('/Home')
        }
        })
      } else if (event === 'SIGNED_OUT') {
        console.log("Usuario no autenticado");
        navigate('/Login');
      } else {
        if (event !== 'INITIAL_SESSION') {
          console.log("Cambio de estado de autenticación:", event);
        }
      }
    });
  }, [navigate]);
  const primeraVez = async (setId) => {
    try {
        const { data } = await supabase
            .from('user')
            .select('first_time')
            .eq('id_user', setId);
        return data[0].first_time;
    } catch (error) {
        console.error(error);
        return null;
    }
}
  return (
      <div className="App">
        <Routes>
          <Route path='/' element={<Login />}/>
          <Route path='/Login' element={<Login />} />
          <Route path='/Register' element={<Register/>}/>
          <Route path='/Home' element={<Home />} />
          <Route path='/Usuario' element={<Usuario/>} />
          <Route path='/Primera' element={<Primera/>}/>
          <Route path='/Coockies' element={<Coockies/>}/>
          <Route path='/Nosotros' element={<Nosotros/>}/>
          <Route path='/Chat' element={<Chat/>}/>
          <Route path='/OlvidoContraseña' element={<OlvidoContraseña/>}></Route>
          <Route path='/ReseteoContraseña' element={<ReseteoContraseña/>}></Route>
        </Routes>
      </div>
  );
}
export default App;