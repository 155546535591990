import React, { useEffect, useState } from 'react';
import { supabase } from '../supabase/client';
import './Usuario.css';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const buttonClass = 'bg-blue-500 text-white p-2 rounded-lg absolute top-0 right-0 mt-4 mr-4';
const titleClass = 'text-2xl font-bold mt-4';

const Profile = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [idUsuario, setId] = useState('');
    const [idseguidor, setidseguidor] = useState('');
    const [imgPerfil, setImgPerfil] = useState('');
    const [publicaciones, setPublicaciones] = useState([]);
    const [comentarios, setComentarios] = useState([]);
    const [desplegableAbierto, setDesplegableAbierto] = useState(null);
    const [usuarioid, setUsuarioid] = useState('');
    const [likes, setLikes] = useState({});
    const [dislikes, setDislikes] = useState({});
    const [numeroComentarios, setNumeroComentarios] = useState([]);
    const [numeroComentariosAnterior, setNumeroComentariosAnterior] = useState(0);
    const [textosTraducidos, setTextosTraducidos] = useState([]);
    const [nombreUsuarioComentario, setNombreUsuarioComentario] = useState('');
    const [idUsuarioComentario, setIdUsuarioComentario] = useState('');
    const [eventos, setEventos] = useState([]);
    const [bio , setBio] = useState('');
    const [userInteractions, setUserInteractions] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await supabase.auth.getUser();
                if (data) {
                    setUsername(data.user.user_metadata.username);
                    setId(data.user.user_metadata.sub);
                    const biografia = await bioid(data.user.user_metadata.sub);
                    setBio(biografia);
                    const imageUrl = await imagenRecogida(data.user.user_metadata.sub);
                    setImgPerfil(imageUrl);
                    obtenerPublicaciones(data.user.user_metadata.sub);
                }
            } catch (error) {
                console.error('Error fetching user data:', error.message);
            }
        };
        fetchData();
    }, []);
    const bioid = async (setId) => {
        try {
            const { data } = await supabase
                .from('user')
                .select('bio')
                .eq('id_user', setId);
            return data[0].bio;
        } catch (error) {
            console.error(error);
            return null;
        }
    };
    const handleFileUpload = async (event) => {
        try {
            const file = event.target.files[0];
            const { data, error } = await supabase.storage.from('pruebas/FotosPruebas').upload(file.name, file);
            if (error) {
                throw error;
            }
            const imageName = data.path;
            await imagenUsuario(imageName);
        } catch (error) {
            console.error('Error handling file upload:', error.message);
        }
    };

    const imagenUsuario = async (imageName) => {
        try {
            const { data, error } = supabase.storage
                .from('pruebas/FotosPruebas')
                .getPublicUrl(imageName);
            if (error) {
                throw error;
            }
            const imageUrl = data.publicUrl;

            const { error: updateError } = await supabase
                .from('user')
                .update({ profile_picture: imageUrl })
                .eq('id_user', idUsuario);
            if (updateError) {
                throw updateError;
            }
        } catch (error) {
            console.error('Error updating profile picture:', error.message);
        }
    };

    const imagenRecogida = async (setId) => {
        try {
            const { data } = await supabase
                .from('user')
                .select('*')
                .eq('id_user', setId);
            return data[0].profile_picture;
        } catch (error) {
            console.error(error);
            return null;
        }
    };
    /*comentarios y publicaciones*/

  const handleTraducirClick = async (comentario, index) => {
    const API_KEY = 'ca9d6ebeb7msh728aa6c3563c780p194862jsnfb3d152aea91';
    const sourceLanguage = 'es';
    const targetLanguage = 'en';
    const texto = comentario;

    const url = `https://deep-translate1.p.rapidapi.com/language/translate/v2`;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-RapidAPI-Key': API_KEY,
        'X-RapidAPI-Host': 'deep-translate1.p.rapidapi.com'
      },
      body: JSON.stringify({
        q: texto,
        source: sourceLanguage,
        target: targetLanguage,
        format: 'text'
      })
    };

    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      if (data && data.data && data.data.translations && data.data.translations.translatedText) {
        const nuevosTextosTraducidos = [...textosTraducidos];
        nuevosTextosTraducidos[index] = data.data.translations.translatedText;
        setTextosTraducidos(nuevosTextosTraducidos);
      } else {
        console.error('No se pudo obtener la traducción');
      }
    } catch (error) {
      console.error('Error al traducir:', error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
        try {
            const { data } = await supabase.auth.getUser();
            if (data) {
                setId(data.user.user_metadata.sub);
            }
        } catch (error) {
            console.error('Error fetching user data:', error.message);
        }
    };
    fetchData();
  }, []);
  
  //------------------------------------------------Mostrar Publicacion---------------------------------------------------------------------
  useEffect(() => {
    obtenerPublicaciones();
  }, []);

  async function obtenerPublicaciones(id_usuario) {
    try {
      const { data, error } = await supabase
        .from('post')
        .select('content, description,id_post')
        .eq('id_user',id_usuario)
        .order('created_at', { ascending: false });
  
      if (error) {
        throw error;
      }
  
      setPublicaciones(data);
    } catch (error) {
      console.error('Error al obtener las publicaciones:', error.message);
    }
  }
    useEffect(() => {
      const fetchNumeroComentarios = async () => {
        try {
          const comentariosPromises = publicaciones.map(publicacion =>
            fetchComentarios(publicacion.id_post)
          );
          const comentarios = await Promise.all(comentariosPromises);
          setNumeroComentarios(comentarios.map(comentariosPorPublicacion => comentariosPorPublicacion.length));
        } catch (error) {
          console.error('Error al obtener el número de comentarios:', error.message);
        }
      };
    
      fetchNumeroComentarios();
    }, [publicaciones]);
    useEffect(() => {
      const fetchData = async () => {
        try {
          const { data } = await supabase.auth.getUser();
          if (data) {
            setNombreUsuarioComentario(data.user.user_metadata.username);
            setIdUsuarioComentario(data.user.user_metadata.sub);
          }
        } catch (error) {
          console.error('Error fetching user data:', error.message);
        }
      };
      fetchData();
    }, []);
    const fetchComentarios = async (postId) => {
      try {
        const { data, error } = await supabase
          .from('comment')
          .select('id_comment, comment, id_user, likes, dislikes, created_at', { count: 'exact' })
          .eq('id_post', postId)
          .order('created_at', { ascending: false });
    
        if (error) {
          throw error;
        }
    
        if (data) {
          const comentarios = await Promise.all(data.map(async (comentario) => {
            const { data: userData } = await supabase
              .from('user')
              .select('username, profile_picture')
              .eq('id_user', comentario.id_user);
    
            if (userData && userData.length > 0) {
              return {
                id_comment: comentario.id_comment,
                comment: comentario.comment,
                id_user: comentario.id_user,
                username: userData[0].username,
                profile_picture: userData[0].profile_picture,
                likes: comentario.likes || 0,
                dislikes: comentario.dislikes || 0,
                created_at: comentario.created_at,
              };
            } else {
              console.error('No se encontraron datos de usuario para el comentario:', comentario.id_user);
              return null;
            }
          }));
    
          return comentarios.filter(comentario => comentario !== null);
        }
      } catch (error) {
        console.error('Error al obtener comentarios:', error.message);
        return [];
      }
    };
    
    const mostrarPublicaciones = (publicaciones) => {
      const dropdownRefs = Array(publicaciones.length).fill(null);
      return publicaciones.map((publicacion, index) => (
        <div key={index} className="publicado">
          <div className='comentario-bonito'>
            <div><img src={publicacion.content} alt={`Publicación ${index + 1}`} className="imagen-publicada" /></div>
            <div><textarea className="texto-publicado-usuario" value={publicacion.description} readOnly></textarea></div>
            <div>
              <button onClick={() => handleEliminarPublicacion(publicacion.id_post)} className='eliminar-publicacion'>🗑️</button>
            </div>
          </div>
          <button className='boton' value={publicacion.id_post} onClick={() => toggleDropdown(index, dropdownRefs, publicacion.id_post)}>
            <span>{numeroComentarios[index]}</span>💬
          </button>
          
          <div className="desplegable" style={{ display: 'none' }} ref={(ref) => dropdownRefs[index] = ref}>
            <div className="contenedor-scroll" value={publicacion.id_post}>
              {comentarios.map((comentario, comentarioIndex) => (
                <div key={comentarioIndex} className="comentario-container">
                  <div className="usuario-info">
                    <img src={comentario.profile_picture} className="Imagen-Perfil-Usuario" />
                    <p className="nombre-usuario">{comentario.username}</p>
                  </div>
                  <div className="comentario-texto">
                    <div>
                      <textarea className="comentario" readOnly value={comentario.comment}></textarea>
                      <textarea className="comentario-traducido" readOnly value={textosTraducidos[comentarioIndex]} style={{ display: textosTraducidos[comentarioIndex] ? 'block' : 'none' }}></textarea>
                    </div>
                    <div>
                      <button className="boton-traducir" onClick={() => handleTraducirClick(comentario.comment, comentarioIndex)}>Traducir</button> 
                      <button className='boton-like' onClick={() => handleLikeClick(comentario.id_comment)}>
                        <span>{likes[comentario.id_comment] || comentario.likes}</span>👍🏾
                      </button>
                      <button className='boton-dislike' onClick={() => handleDislikeClick(comentario.id_comment)}>
                        <span>{dislikes[comentario.id_comment] || comentario.dislikes}</span>👎🏿
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='comentario-cajadecomentario'>
              <textarea
                className="caja-texto-comentario"
                id={`comentario-${publicacion.id_post}`}
                placeholder="Escribe aquí tu comentario..."
              ></textarea>
              <div>
                <button
                  className='boton-enviar-comentario'
                  onClick={() => handleEnviarComentarioClick(publicacion.id_post, index)}
                  data-post-id={publicacion.id_post}
                >
                  ✉️
                </button>
              </div>
            </div>
          </div>
        </div>
      ));
    };
    
    const handleLikeClick = async (commentId) => {
      if (!commentId) {
        console.error('commentId es indefinido');
        return;
      }
    
      try {
        const { data: comment, error: fetchError } = await supabase
          .from('comment')
          .select('likes, dislikes')
          .eq('id_comment', commentId)
          .single();
    
        if (fetchError) {
          console.error('Error al obtener el comentario:', fetchError.message);
          return;
        }
    
        const userInteraction = userInteractions[commentId] || { liked: false, disliked: false };
    
        if (userInteraction.liked) {
          // Quitar el like
          const newLikeCount = comment.likes - 1;
          const { error: updateError } = await supabase
            .from('comment')
            .update({ likes: newLikeCount })
            .eq('id_comment', commentId);
    
          if (updateError) {
            console.error('Error al quitar el like:', updateError.message);
            return;
          }
    
          setLikes(prevLikes => ({ ...prevLikes, [commentId]: newLikeCount }));
          setUserInteractions(prevInteractions => ({
            ...prevInteractions,
            [commentId]: { liked: false, disliked: false }
          }));
        } else {
          // Añadir el like
          const newLikeCount = comment.likes + 1;
          const updates = { likes: newLikeCount };
          if (userInteraction.disliked) {
            updates.dislikes = comment.dislikes - 1;
          }
    
          const { error: updateError } = await supabase
            .from('comment')
            .update(updates)
            .eq('id_comment', commentId);
    
          if (updateError) {
            console.error('Error al añadir el like:', updateError.message);
            return;
          }
    
          setLikes(prevLikes => ({ ...prevLikes, [commentId]: newLikeCount }));
          if (userInteraction.disliked) {
            setDislikes(prevDislikes => ({ ...prevDislikes, [commentId]: updates.dislikes }));
          }
          setUserInteractions(prevInteractions => ({
            ...prevInteractions,
            [commentId]: { liked: true, disliked: false }
          }));
        }
      } catch (error) {
        console.error('Error al manejar el like:', error.message);
      }
    };
    
    const handleDislikeClick = async (commentId) => {
      if (!commentId) {
        console.error('commentId es indefinido');
        return;
      }
    
      try {
        const { data: comment, error: fetchError } = await supabase
          .from('comment')
          .select('likes, dislikes')
          .eq('id_comment', commentId)
          .single();
    
        if (fetchError) {
          console.error('Error al obtener el comentario:', fetchError.message);
          return;
        }
    
        const userInteraction = userInteractions[commentId] || { liked: false, disliked: false };
    
        if (userInteraction.disliked) {
          // Quitar el dislike
          const newDislikeCount = comment.dislikes - 1;
          const { error: updateError } = await supabase
            .from('comment')
            .update({ dislikes: newDislikeCount })
            .eq('id_comment', commentId);
    
          if (updateError) {
            console.error('Error al quitar el dislike:', updateError.message);
            return;
          }
    
          setDislikes(prevDislikes => ({ ...prevDislikes, [commentId]: newDislikeCount }));
          setUserInteractions(prevInteractions => ({
            ...prevInteractions,
            [commentId]: { liked: false, disliked: false }
          }));
        } else {
          // Añadir el dislike
          const newDislikeCount = comment.dislikes + 1;
          const updates = { dislikes: newDislikeCount };
          if (userInteraction.liked) {
            updates.likes = comment.likes - 1;
          }
    
          const { error: updateError } = await supabase
            .from('comment')
            .update(updates)
            .eq('id_comment', commentId);
    
          if (updateError) {
            console.error('Error al añadir el dislike:', updateError.message);
            return;
          }
    
          setDislikes(prevDislikes => ({ ...prevDislikes, [commentId]: newDislikeCount }));
          if (userInteraction.liked) {
            setLikes(prevLikes => ({ ...prevLikes, [commentId]: updates.likes }));
          }
          setUserInteractions(prevInteractions => ({
            ...prevInteractions,
            [commentId]: { liked: false, disliked: true }
          }));
        }
      } catch (error) {
        console.error('Error al manejar el dislike:', error.message);
      }
    };
    const toggleDropdown = async (index, dropdownRefs, postId) => {
      const dropdown = dropdownRefs[index];
      if (dropdown) {
        if (postId === desplegableAbierto) {
          dropdown.style.display = 'none';
          setDesplegableAbierto(null);
          setTextosTraducidos(Array(publicaciones.length).fill(''));
        } else {
          dropdown.style.display = 'block';
          if (desplegableAbierto !== null) {
            const previousDropdown = dropdownRefs.find((ref, i) => publicaciones[i].id_post === desplegableAbierto);
            if (previousDropdown) {
              previousDropdown.style.display = 'none';
            }
          }
          setDesplegableAbierto(postId);
          const comentarios = await fetchComentarios(postId);
          setComentarios(comentarios);
          const numeroComentariosActualizado = comentarios.length;
          setNumeroComentariosAnterior(numeroComentarios[index]);
          setNumeroComentarios(numeroComentarios.map((numero, idx) => (idx === index ? numeroComentariosActualizado : numero)));
          const likesMap = {};
          const dislikesMap = {};
          comentarios.forEach(comentario => {
            likesMap[comentario.id_comment] = comentario.likes;
            dislikesMap[comentario.id_comment] = comentario.dislikes;
          });
          setLikes(likesMap);
          setDislikes(dislikesMap);
        }
      }
    };
    const handleEnviarComentarioClick = async (postId, publicacionIndex) => {
      try {
        const postIdAttr = `comentario-${postId}`;
        const TextoComentario = document.getElementById(postIdAttr);
        const miTextoComentado = TextoComentario.value;
        const { error } = await supabase
          .from('comment')
          .insert([
            {
              id_user: idUsuarioComentario,
              id_post: postId,
              comment: miTextoComentado,
            }
          ]);
        if (!error) {
          TextoComentario.value = '';
          console.log(`Enviando comentario para la publicación con ID ${postId}`);
          const comentariosActualizados = await fetchComentarios(postId);
          setComentarios(comentariosActualizados);
          const numeroComentariosActualizado = comentariosActualizados.length;
          setNumeroComentarios(numeroComentarios.map((numero, idx) => (idx === publicacionIndex ? numeroComentariosActualizado : numero)));
        }
      } catch (error) {
        console.error('Error al enviar el comentario:', error.message);
      }
    };

    const goToHome = () => {
        navigate('/Home');
    };
    /*--------------------------eventos---------------------------------------------------------------------------------*/
    const obtenerEventos = async () => {
      try {
        const { data, error } = await supabase
          .from('events')
          .select('*')
          .order('time', { ascending: true });
        if (error) {
          throw error;
        }
        const eventosFormateados = data.map(evento => ({
          ...evento,
          time: new Date(evento.time).toLocaleDateString('es-ES', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          })
        }));
        setEventos(eventosFormateados);
      } catch (error) {
        console.error('Error al obtener los eventos:', error.message);
      }
    };
    
    useEffect(() => {
      obtenerEventos();
    }, []);
    const fechaActual = new Date();
    const eventoProximo = eventos.find(evento => new Date(evento.time.split('/').reverse().join('-')) > fechaActual);
      const handleEditClick = () => {
        setEditing(true);
        setNewBio(bio);
    };
      
    const [editing, setEditing] = useState(false);
    const [newBio, setNewBio] = useState('');
    const handleSaveClick = async () => {
        try {
            await supabase
                .from('user')
                .update({
                    bio: newBio,
                })
                .eq('id_user', idUsuario);
            console.log('Nueva biografía guardada:', newBio);
            setBio(newBio);
            setEditing(false);
        } catch (error) {
            console.error('Error enviando la biografía:', error.message);
        }
    };
    const handleChange = (event) => {
        setNewBio(event.target.value);
    };
/*---------------------------------------------personas que sigo---------------------------------------------------------------*/
  const [usuariosSeguidos, setUsuariosSeguidos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await supabase.auth.getUser();
        if (data) {
          setidseguidor(data.user.user_metadata.sub);
          const followedUserIds = await selectFollowersWithEq(data.user.user_metadata.sub);
          const followedUsers = await selectUsersByIds(followedUserIds);
          setUsuariosSeguidos(followedUsers);
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    };
    fetchData();
  }, []);

  const selectFollowersWithEq = async (idseguidor) => {
    try {
      const { data, error } = await supabase
        .from('followers')
        .select('id_user_secundario')
        .eq('id_user_principal', idseguidor);

      if (error) {
        throw error;
      }

      return data.map(item => item.id_user_secundario) || [];
    } catch (error) {
      console.error('Error selecting followers:', error.message);
      return [];
    }
  };

  const selectUsersByIds = async (userIds) => {
    try {
      const { data, error } = await supabase
        .from('user')
        .select('*')
        .in('id_user', userIds);

      if (error) {
        throw error;
      }

      return data || [];
    } catch (error) {
      console.error('Error selecting users by IDs:', error.message);
      return [];
    }
  };

  const handleUnfollowButtonClick = async (userIdToUnfollow) => {
    try {
      await deleteDataFromTable(idseguidor, userIdToUnfollow);
      setUsuariosSeguidos(prevUsers => prevUsers.filter(user => user.id_user !== userIdToUnfollow));
    } catch (error) {
      console.error('Error unfollowing user:', error.message);
    }
  };

  const deleteDataFromTable = async (idusuarioseguidor, userIdToUnfollow) => {
    try {
      const { data, error } = await supabase
        .from('followers')
        .delete()
        .eq('id_user_principal', idusuarioseguidor)
        .eq('id_user_secundario', userIdToUnfollow);

      if (error) {
        throw error;
      }

      console.log('Data deleted successfully:', data);
    } catch (error) {
      console.error('Error deleting data from Supabase table:', error.message);
      throw error;
    }
  };
  const handleMensajeClick = (user) => {
    console.log('Usuario seleccionado:', user.id_user);
    navigate('/chat', { state: { receiverUserId: user.id_user } });
  };
  const mostrarUsuarios = (users) => {
    return users.map((user, index) => (
        <div key={index} className="usuario">
          <img src={user.profile_picture} alt={`Usuario ${user.username}`} className="imagenUsuarioPosible" />
          <span className="nombre-usuario">Nombre: {user.username}</span>
          <button className='Mensaje' onClick={() => handleMensajeClick(user)}>✉️</button>
          <button className='dejar-seguir' onClick={() => handleUnfollowButtonClick(user.id_user)}>X</button>
        </div>
    ));
  };
  
  //-----------------------------------------------------Dar de Baja--------------------------------------------------
  const handleDarDeBaja = () => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Dar de baja',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Paso 1: Obtener el ID del usuario actual
          const { data } = await supabase.auth.getUser();
          console.log(data)
          if (data) {
            let { error } = await supabase
              .from('user')
              .delete()
              .eq('id_user', data.user.user_metadata.sub);
  
            if (error) {
              throw error;
            }
  
            // Paso 3: Cerrar sesión
            await supabase.auth.signOut();
  
            // Mostrar mensaje de éxito
            Swal.fire(
              '¡Dado de baja!',
              'El usuario ha sido dado de baja y se ha cerrado la sesión.',
              'success'
            );
          } else {
            throw new Error('No se pudo obtener la información del usuario.');
          }
        } catch (error) {
          // Manejo de errores
          console.error('Error al dar de baja:', error.message);
          Swal.fire(
            'Error',
            'Hubo un problema al dar de baja al usuario.',
            'error'
          );
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Manejar la cancelación
        Swal.fire(
          'Cancelado',
          'El usuario no ha sido dado de baja.',
          'error'
        );
      }
    });
  };
  //------------------------------------------EliminarPublicacion-----------------------------------------------------------------
  const handleEliminarPublicacion = async (id_post) => {
    Swal.fire({
      title: '¿Quieres borrar la publicacion?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Borrar Publicacion',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { error: errorComentarios } = await supabase
            .from('comment')
            .delete()
            .eq('id_post', id_post);
          
          if (errorComentarios) {
            throw errorComentarios;
          }
          
          const { error: errorPublicacion } = await supabase
            .from('post')
            .delete()
            .eq('id_post', id_post);
  
          if (errorPublicacion) {
            throw errorPublicacion;
          }
  
          setPublicaciones((prevPublicaciones) => prevPublicaciones.filter((pub) => pub.id_post !== id_post));
          
          Swal.fire(
            '¡Publicacion borrada!',
            'La publicacion fue borrada',
            'success'
          );
        } catch (error) {
          console.error('Error al eliminar la publicación:', error.message);
          Swal.fire(
            'Error',
            'Hubo un problema al borrar la publicacion',
            'error'
          );
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelado',
          'No se borró la publicacion',
          'error'
        );
      }
    });
  };
    return (
      <body className='body-usuario'>
                      <div className="bg-white dark:bg-zinc-800 min-h-screen flex flex-col items-center justify-center relative">

              <div className='cabecera-usuario'>

                <div className='cabecera-izquierda'>
                  <img src={imgPerfil} alt="Profile Photo" className="Imagen-Perfil-Usuario" />
                  <input type="file" onChange={handleFileUpload} className='boton-modificar-foto'/>
                </div>

                <div className='cabecera-medio'>
                  <h1 className={titleClass}>Bienvenido {username}</h1>
                  <h5>Biografia:
                    {editing ? (
                      <div className='guardar-bio'>
                        <textarea
                          className="biografiaModificada"
                          value={newBio}
                          onChange={handleChange}
                          placeholder="Escribe tu nueva biografía..."
                        ></textarea>
                        <button className="boton-guardar" onClick={handleSaveClick}>Guardar</button>
                      </div>
                    ) : (
                    <>
                    {" "+bio}
                    <button className="botonmodificar" onClick={handleEditClick}>✏️</button>
                    </>
                    )}
                  </h5>
                </div>

                <div className='cabecera-derecha'>
                  <button className={buttonClass} onClick={goToHome}>Inicio</button>
                </div>
              </div>


              <div className='el-cuerpo'>
              <div className="tabla-eventos-container-usuario">
              <div className="tabla-eventos-usuario">
                <h1>Eventos</h1>
                <table>
                  <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>Título</th>
                      <th>Descripción</th>
                      <th>Usuario</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventos.map((evento, index) => (
                      <tr
                        key={index}
                        style={{ backgroundColor: evento === eventoProximo ? 'lightyellow' : 'inherit' }}
                      >
                        <td>{evento.time}</td>
                        <td>{evento.title}</td>
                        <td>{evento.description}</td>
                        <td>{evento.name_user}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              </div>
              <div className="comentarios-propios">
                <h1>Mis Publicaciones</h1>
                {mostrarPublicaciones(publicaciones)}
              </div>
              <div className="personas-que-sigo">
                <h1>Siguiendo</h1>
                {mostrarUsuarios(usuariosSeguidos)}
              </div>
              </div>
              </div>
              <div>
                <button onClick={handleDarDeBaja}>Dar de baja</button>
              </div>
      </body>
      
    );
};

export default Profile;
   
