import React, { useState } from "react";
import { supabase } from "../supabase/client";
import './Style.css';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";

function OlvidoContraseña() {
  const [email, setEmail] = useState("");
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [errorLogin, setErrorLogin] = useState(null);
  const navigate = useNavigate();
  
  const handleOlvidoContraseña = async (e) => {
    e.preventDefault();
    setLoadingLogin(true);
    setErrorLogin(null);
    alert("Se ha enviado un token a este correo electrónico")
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email);
      if (error) {
        throw error;
      }
      navigate('/ReseteoContraseña');
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      setErrorLogin("Error al iniciar sesión. Por favor, verifica tus credenciales.");
    } finally {
      setLoadingLogin(false);
    }
  };

  return (
    <body className="body-login">
      <div className="container" id="container">
        <div className="form-container sign-up-container">
          <form onSubmit={handleOlvidoContraseña}>
            <h1>¿Olvidaste tu Contraseña?</h1>
            <h4>Dinos tu email y se te enviará un token</h4>
            <input
              type="email"
              placeholder="Correo electronico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              type="submit"
              className="botonIniciarSesion"
              disabled={loadingLogin}
            >
              {loadingLogin ? "Cargando..." : "Enviar Correo"}
            </button>
            {errorLogin && <div className="error-message">{errorLogin}</div>}
          </form>
          <Link to="/Login">Vuelve al login</Link>
        </div>
      </div>
    </body>  
  );
}

export default OlvidoContraseña;
