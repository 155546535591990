import React, { useState } from "react";
import { Link } from "react-router-dom";
import { supabase } from "../supabase/client";
import './Style.css';

function Register() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [errorRegister, setErrorRegister] = useState(null);

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    setLoadingRegister(true);
    setErrorRegister(null);
    
    if (password !== repeatPassword) {
      setErrorRegister("Las contraseñas no coinciden. Por favor, inténtalo de nuevo.");
      setLoadingRegister(false);
      return;
    }
    alert("Revise la bandeja de su correo electronico")
    try {
      
      const { error } = await supabase.auth.signUp({
        email,
        password,
        options:{
          data: {
            username: username,
            email: email
          }
        }
      });

      if (error) {
        throw error;
      }

    } catch (error) {
      console.error("Error al registrarse:", error);
      setErrorRegister("Error al registrarse. Por favor, verifica tus datos.");
    } finally {
      setLoadingRegister(false);
    }
  };

  return (
    <body className="body-login">
      <div className="container" id="container">
      <div className="form-container sign-up-container">
        <form onSubmit={handleRegisterSubmit}>
          <h1>Crea una cuenta</h1>
          <h4>Rellena los siguientes campos para tu cuenta</h4>
          <input type="text" placeholder="Nombre de usuario" value={username} onChange={(e) => setUsername(e.target.value)} />
          <input type="email" placeholder="Correo electronico" value={email} onChange={(e) => setEmail(e.target.value)} />
          <input type="password" placeholder="Contraseña minima de 6 digitos" value={password} onChange={(e) => setPassword(e.target.value)} />
          <input type="password" placeholder="Repetir Contraseña" value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)} />
          <button type="submit" disabled={loadingRegister}>
            {loadingRegister ? "Cargando..." : "Registrarse"}
          </button>
          {errorRegister && <div className="error-message">{errorRegister}</div>}
        </form>
        <Link to="/login">¿Ya tienes cuenta? Inicia sesión aquí</Link>
      </div>
    </div>
    </body>
    
  );
}

export default Register;
