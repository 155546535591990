import React from 'react';
import './whatsEstilo.css'; // Asegúrate de importar el archivo CSS
import { Link } from "react-router-dom";

const WhatsAppButton = () => {
    return (
      <Link to="/Chat">
        <a
        href="https://wa.me/yourphonenumber" // Reemplaza "yourphonenumber" con tu número de WhatsApp
        className="whatsapp-button"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fas fa-envelope"></i> {/* Usa las clases correctas */}
      </a>
      </Link>
    );
  };
  
  export default WhatsAppButton;