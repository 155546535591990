// Supongamos que esta función se encuentra en un archivo como supabaseClient.js
import { supabase } from '../supabase/client';

async function getUserDataAsync(userId) {
    try {
        
        const { data, error } = await supabase
            .from('user')
            .select('username, profile_picture')
            .eq('id_user', userId)
        if (data) {
            return {
                username: data[0].username,
                profilePicture: data[0].profile_picture
            };
        } else {
            throw new Error(`User with ID ${userId} not found`);
        }
    } catch (error) {
        console.error('Error fetching user data:', error.message);
    }
}

export default getUserDataAsync;
