import React from 'react';
import './botonHome.css'; // Asegúrate de importar el archivo CSS
import { Link } from "react-router-dom";

const BotonHome = () => {
    return (
        <Link to="/Home">
            <a
                href="https://wa.me/yourphonenumber" // Reemplaza "yourphonenumber" con tu número de WhatsApp
                className="home-button"
                target="_blank"
                rel="noopener noreferrer"
            >
                <i className="fas fa-home"></i> {/* Usa las clases correctas */}
            </a>
        </Link>
    );
};
  
export default BotonHome;